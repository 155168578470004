import { useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "./App.css"; // 스타일을 위한 CSS 파일 포함

function Result() {
  const location = useLocation();
  const data = location.state; // This should be your array of objects

  //const [isModalOpen, setIsModalOpen] = useState(false);
  //const [comment, setComment] = useState(""); // 댓글 상태 관리
  //const [switchMsg, setSwitch] = useState("답변하기");
  //const [selectedIndex, setSelectedIndex] = useState(null); // 현재 클릭된 항목의 인덱스
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [alertOpen, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // 컴포넌트가 마운트될 때 이벤트 리스너 추가
    window.addEventListener('resize', handleResize);

    // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [copyMsg, setCopyMsg] = useState('');

  const copyQOnly = () => {
    data.map((item, index) => (
      setCopyMsg(setCopyMsg + "\nQ: " + item.Q)
    ))
  }

  const copyQA = () => {
    data.map((item, index) => (
      setCopyMsg(setCopyMsg + "\nQ: " + item.Q + "\nA:" + item.A)
    ))
  }

  // Check if data is an array
  if (!Array.isArray(data)) {
    console.error("Expected an array but received:", data);
    return <p className="title404">올바르지 않은 데이터입니다.</p>;
  }

  // 오른쪽 클릭 시 모달 열기
  const openModal = (event, index) => {
    event.preventDefault(); // 기본 오른쪽 클릭 메뉴 방지
    //setSelectedIndex(index); // 클릭한 질문의 인덱스 저장
    //setIsModalOpen(true); // 모달 열기
  };

  /**const switchIsModalOpen = () => {
    if (isModalOpen) {
      setSwitch("예상 답변 보기")
    } else {
      setSwitch("답변하기")
    }
    setIsModalOpen(!isModalOpen);
  }**/

  // 댓글 입력 핸들러
  /**const handleCommentChange = (e) => {
    setComment(e.target.value);
  };**/

  return (
    <div>
      { isMobile ? (
    <div className="coming-soon-container">
        <p>모바일 화면은 아직 준비 중입니다.</p>
    </div>) : (
      <>
      {alertOpen && (
        <div className="alertBg">
          <div className="alertWrapper">
            <div className="alertTop">알림</div>
            <div className="alertMsg">{alertMessage}</div>
            <span onClick={ ()=> {setAlert(false)} } className="alertClose">닫기</span>
          </div>
        </div>
      )}
      <div className="title result">대입 면접 질문 생성기</div>
      <div className="explanation">입력된 세특을 바탕으로 질문을 생성하였습니다 오른쪽 버튼을 통해 텍스트로 복사할 수 있으며</div>
      <div className="explanation">생성된 예상 답변을 볼 수 있습니다</div>
      <div className="line"></div>
      <span className="copySpan" onClick={ ()=> {copyQOnly();
        if (navigator.clipboard && navigator.clipboard.writeText) {
         navigator.clipboard.writeText(copyMsg)
        } else {
          setAlertMessage('클립보드 복사가 지원되지 않는 브라우저입니다.');
          setAlert(true)
          console.warn('이 브라우저는 클립보드 API를 지원하지 않습니다.');
        }
         }}>질문 복사</span>
      <span className="copySpan" onClick={ ()=> {copyQA();
        if (navigator.clipboard && navigator.clipboard.writeText) {
         navigator.clipboard.writeText(copyMsg)
        } else {
          setAlertMessage('클립보드 복사가 지원되지 않는 브라우저입니다.');
          setAlert(true)
          console.warn('이 브라우저는 클립보드 API를 지원하지 않습니다.');
        }

      }}>질문 & 답변 복사</span>
      {data.map((item, index) => (
        <div key={index} className="question-container" onContextMenu={(event) => openModal(event, index)}>
          <div className="queWrapper">
            <div className="qTitle">Q.</div>
            <div className="Q">{item.Q}</div>
          </div>
          <div className="ansWrapper">
            <div className="aTitle">A.</div>
            <div className="A">{item.A}</div>
          </div>
          {/**!isModalOpen && (
            <div className="ansWrapper">
            <div className="aTitle">A.</div>
            <div className="A">{item.A}</div>
          </div>
          )}
          {isModalOpen && (
        <div className="modal_r">
          <div className="modal-content">
            <h3 className="score">/10</h3>
            <textarea
              value={comment}
              onChange={handleCommentChange}
              placeholder="답변을 입력하세요"
              className="answer-box"
            />
            <button className="submit-button">확인</button>
          </div>
        </div>
      )*/}
        </div>
      ))}
      <div className="bottomSpace"></div>
    </>)}</div>
  );
}

export default Result;
