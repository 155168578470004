import React from 'react';
import { Routes, Route, Router } from 'react-router-dom';
import Home from './pages/Home';
import Result from './pages/Result';
import NotFound from './pages/NotFound'

function App() {
  return (
      <Routes>
         <Route path='/' element={<Home />} />
         <Route path='/result' element={<Result />}  />
         <Route path="/*" element={<NotFound />} />
      </Routes>
  );
}

export default App;
